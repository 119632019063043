import React from 'react'
import loading from '../../assets/static/loading.gif'
import './styles.scss'

const Loading = () => {
  return (
    <div className="loader">
      <img src={loading} alt="" /> 
    </div>
  )
}

export default Loading
